<template>
  <div class="page">
    <section>
      <div class="top">
        <span class="title">我要推荐</span>
        <el-button type="text" @click="dialogVisible = true">
          <i class="iconfont xinzeng"></i>
        </el-button>

        <el-dialog title="提示" :visible.sync="dialogVisible" width="803px">
          <div class="popupContent">
            <span class="contentTitle">添加推荐医生</span>
            <el-cascader
              v-model="city"
              :options="options"
              :props="{ value: 'name', label: 'name', children: 'datas' }"
              placeholder="省市"
            ></el-cascader>
            <el-cascader
              v-model="hospital"
              :options="HospitalList"
              :props="{ value: 'hname', label: 'hname' }"
              placeholder="医院名称（选择前请先选择省市）"
            ></el-cascader>
            <!-- <span class="tips"></span> -->
            <el-cascader
              v-model="title"
              :options="DocterPosition"
              :props="{ value: 'name', label: 'name' }"
              placeholder="职称"
            ></el-cascader>
            <el-input
              autocomplete="off"
              v-model="department"
              placeholder="科室"
            ></el-input>
            <el-input
              autocomplete="off"
              v-model="name"
              placeholder="姓名"
            ></el-input>
            <el-input
              autocomplete="off"
              v-model="phone"
              placeholder="手机号码"
            ></el-input>
          </div>
          <span class="contentButton" @click="add">确定</span>
        </el-dialog>
      </div>
      <div class="group" v-if="list.length">
        <div class="recommend" v-for="(item, index) in list" :key="index">
          <div class="address">
            <span>{{
              item.hospital.length > 15
                ? item.hospital.slice(0, 15) + "..."
                : item.hospital
            }}</span>
            <span
              class="text"
              v-if="item.status == 0"
              style="
                 {
                  color: #8f8f8f;
                }
              "
            ></span>
            <!-- <img class="shanchu" v-if="item.status == 0" :src='require("@image/shanchu1.png")' /> -->
            <span
              class="text"
              v-if="item.status == 1"
              style="
                 {
                  color: $--themeColor;
                }
              "
              >成功</span
            >
            <span
              class="text"
              v-if="item.status == 2"
              style="
                 {
                  color: #e60012;
                }
              "
              >未参加</span
            >
            <span
              class="text"
              v-if="item.status == 3"
              style="
                 {
                  color: #8f8f8f;
                }
              "
              >条件不符</span
            >
          </div>
          <div class="jobTitle">
            <span>科室：</span><span>{{ item.department }}</span>
          </div>
          <div class="jobTitle">
            <span>职称：</span><span>{{ item.title }}</span>
          </div>
          <div class="jobTitle">
            <span>姓名：</span><span>{{ item.name }}</span>
          </div>
          <div class="jobTitle">
            <span>电话：</span><span>{{ item.phone }}</span>
          </div>
          <div
            class="iconfont shanchu"
            @click="deleteRecomm(item)"
            title="删除推荐"
          ></div>
        </div>

        <div class="foot-page" v-if="total > limit">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            current-page.sync="5"
            :page-size="limit"
            layout="prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="group" v-else>
        <div class="null-box" style="margin: 0 auto">
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
          />
          <div class="title">暂无数据</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import citys from "@/utils/city";
export default {
  data() {
    return {
      isLoad: true,
      dialogVisible: false,
      city: "",
      department: "",
      title: "",
      name: "",
      phone: "",
      hospital: "",
      options: [], //省市列表
      DocterPosition: [], //职称列表
      HospitalList: [], //医院列表
      // list:[],    //已参与项目ID列表
      list: "",
      page: 1,
      limit: 10,
      total: 0,
    };
  },
  watch: {
    city(val) {
      let name = val[1];
      name = name.slice(0, name.length - 1);
      this.getHospital(name);
    },
  },
  async mounted() {
    for (let i in citys.data) {
      for (let j in citys.data[i].datas) {
        citys.data[i].datas[j].datas = null;
      }
    }
    this.options = citys.data;
    this.xin.isLoadding(true);
    await this.getList();
    this.xin.isLoadding();
    await this.getDocter();
  },
  methods: {
    async deleteRecomm(item) {
      //删除推荐
      this.$msgbox({
        title: "消息",
        message: "您确定要删除该推荐吗?",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            let { code, data } = await this.$researchApi.delRecommend({
              id: item.id,
            });
            done();
            instance.confirmButtonLoading = false;
            if (code == 200) {
              this.showModel = false;
              this.list.splice(
                this.list.findIndex((v) => v.id == item.id),
                1
              );
              this.xin.message("删除成功！");
            }
          } else {
            done();
          }
        },
      }).then((action) => {});
    },
    // 分页
    async handleSizeChange(e) {
      this.page = e;
      this.xin.isLoadding(true);
      await this.getList();
      this.xin.isLoadding(false);
    },
    // 分页
    async handleCurrentChange(e) {
      this.page = e;
      this.xin.isLoadding(true);
      await this.getList();
      this.xin.isLoadding(false);
    },
    // 列表
    async getList() {
      let res = await this.$researchApi.recommendList({
        page: this.page,
        limit: this.limit,
        type: 1,
        questionnaire_id: this.$route.query.id,
      });
      if (res.code == 200) {
        this.list = res.data.data || [];
        this.total = res.data.total;
      }
    },
    async getDocter() {
      await this.$newsApi.getDocterPosition({ type: 2 }).then((res) => {
        if (res.code == 200) {
          this.DocterPosition = res.data;
        }
      });
    },
    getHospital(name) {
      this.$researchApi.otherHospital({ city_name: name }).then((res) => {
        if (res.code == 200) {
          this.HospitalList = res.data;
        } else {
          this.xin.Toast(res.message);
        }
      });
    },
    // 添加
    async add() {
      if (!this.isLoad) {
        return;
      }
      const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
      if (!this.city) {
        return this.$message({ message: "请填写省市!", type: "error" });
      } else if (!this.department) {
        return this.$message({ message: "请填写科室!", type: "error" });
      } else if (!this.title) {
        return this.$message({ message: "请填写职称!", type: "error" });
      } else if (!this.name) {
        return this.$message({ message: "请填写姓名!", type: "error" });
      } else if (!reg.test(this.phone)) {
        return this.$message({
          message: "请填写正确的手机号!",
          type: "error",
        });
      } else {
        let data = {
          relation_id: this.$route.query.id,
          type: 1,
          name: this.name,
          phone: this.phone,
          title: this.title[0],
          hospital: this.hospital[0],
          city: this.city.join("-"),
          department: this.department,
        };
        // if(this.hospital) {
        //     data.hospital = this.hospital
        // }
        this.isLoad = false;
        let res = await this.$researchApi.addRecommendList(data);
        this.isLoad = true;
        if (res.code == 200) {
          this.$message({ message: "添加成功!", type: "success" });
          this.dialogVisible = false;
          this.name = "";
          this.title = "";
          this.phone = "";
          this.city = "";
          this.department = "";
          this.hospital = "";
          await this.getList();
        }
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "@assets/css/themeColor.scss";

.foot-page {
  height: 40px;
  text-align: center;
  width: 100%;
}
::v-deep {
  .el-input {
    margin-top: 25px;
  }
  .el-input--suffix {
    margin-top: 20px;
  }
  .el-input {
    background: none;
    > .el-input__inner {
      box-sizing: border-box;
      font-size: 16px;
      color: #8f8f8f;
      height: 40px;
      margin: 0;
    }
  }
  .el-dialog {
    padding: 50px 50px 37px 50px;
    box-sizing: border-box;
    .popupContent {
      display: flex;
      flex-direction: column;
      > .contentTitle {
        font-size: 24px;
        color: #333333;
        margin-bottom: 30px;
      }
    }
    .contentButton {
      cursor: pointer;
      width: 360px;
      height: 44px;
      background: $--themeColor;
      border: 1px solid $--themeColor;
      border-radius: 23px;
      color: #ffffff;
      text-align: center;
      line-height: 44px;
      margin: 0 auto;
      margin-top: 30px;
    }
  }
}
.page {
  background: #f2f2f2;
  > section {
    display: flex;
    flex-direction: column;
    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 18px;
      margin-top: 30px;
      margin-bottom: 24px;
      > .title {
        font-weight: bold;
      }
      .xinzeng {
        color: $--themeColor;
        margin-left: 1104px;
      }
    }
    > .group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      > .recommend {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 580px;
        background: #ffffff;
        padding: 20px;
        box-sizing: border-box;
        color: #333333;
        margin-top: 40px;
        &:nth-child(1),
        &:nth-child(2) {
          margin-top: 0;
        }
        .shanchu {
          font-size: 30px;
          position: absolute;
          bottom: 20px;
          right: 20px;
          cursor: pointer;
        }
        > .address {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 24px;
          border-bottom: 1px solid #f2f2f2;
          padding-bottom: 19px;
          box-sizing: border-box;
          margin-bottom: 20px;
          > .shanchu {
            color: #8f8f8f;
            margin-left: auto;
            width: 24px;
            height: 24px;
          }
          > .text {
            font-size: 24px;
            color: #8f8f8f;
            margin-left: auto;
          }
        }
        > .jobTitle {
          display: flex;
          flex-direction: row;
          font-size: 18px;
          margin-bottom: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.tips {
  line-height: 30px;
  color: #999999;
  font-size: 12px;
}
</style>